import io from 'socket.io-client';
import mobileDevice from 'ismobilejs';
//
import {
  chatTypes,
  http,
  IS_MOBILE_WIDTH,
  messageStatuses,
  msgTypes
} from '../config/constants';
import store from '../store';
import { openCb } from '../ducks/chatBox/chatBox.action';
import { NotificationManager } from 'react-notifications';
import {
  connect,
  setActiveRoom,
  setUserId,
  storeChatId
} from '../ducks/global/app.action';
import {
  reinitRoomData,
  connectRoom,
  setOperatorInfo,
  setOperatorOffline,
  setOperatorOnline,
  operatorAssignedChat,
  operatorUnassignedChat,
  sendMessage,
  operatorClosedChat,
  loadRoomHistory,
  setNeedAnswer,
  setOperatorTyping,
  unsetOperatorTyping,
  setStatus,
  setChatId,
  clearClosedChatTimer,
  showSendTranscriptForm,
  disconnectRoom,
  setMsgStatus,
  setDomainQualificationTeamStatus,
  clearLamTimer,
  domainQualificationReset
} from '../ducks/global/rooms.action';
import {
  getClearRoomName,
  getCurrentTime,
  getProp,
  getRoomNameFromLocation,
  useDebugger
} from 'utils/helpers';
import { chatAdHoc } from 'utils/adhoc';
import logger from '../utils/logger';
import * as globalFunctions from '../containers/App/globalFunctions';
import StylesConstructor from '../components/FloatingButton/StylesConstructor';

const isPhone = mobileDevice.phone || IS_MOBILE_WIDTH;

export default class ChatConnection {
  socket;
  // What does the above do?
  initProps = {
    active: false,
    userId: null,
    server: null,
    pageUrl: null,
    apiUrl: null,
    configurator: null,
    reconnectDelay: 3000,
    lastReconnectTry: null
  };

  constructor(props = {}) {
    this.props = {
      active: false,
      userId: props.userId,
      server: `${http.CHAT_SERVER_SCHEME}://${props.server}/c`,
      pageUrl: props.pageUrl,
      apiUrl: http.API_URL,
      configurator: props.configurator,
      useDebugger: useDebugger(),
      referrer: props.referrer || null,
      reconnectDelay: 3000,
      lastReconnectTry: null
    };
    this._init();
    this._addListeners();
    this._chatAdHoc = chatAdHoc();
  }

  _init() {
    if (this.socket) {
      this.socket.destroy();
      delete this.socket;
      this.socket = null;
    }

    logger.verbose(
      `Initializing socket conenction with server: ${this.props.server}`
    );
    this.socket = io(this.props.server, {
      transports: ['websocket'],
      query: 'v=' + globalFunctions.VisitorChatGetVersion()
    });
    this.props.active = true;

    /**
     * First identity - if user id null, server response create new user id
     */
    this._attachListenerToSocket('connect', () => {
      this._identity(this.props.userId);
    });
  }

  _emitEvent = (eventType, data) => this.socket.emit(eventType, data);

  _attachListenerToSocket = (eventKey, handler) => {
    const eventName = msgTypes[eventKey] || eventKey;

    if (typeof getProp(this, 'socket.on') !== 'function') {
      // Fix issue with behavior when the socket.io-client instance not already exists
      return setTimeout(
        () => this._attachListenerToSocket(eventKey, handler),
        0
      );
    }

    if (!this.props.useDebugger) {
      this.socket.on(eventName, handler);
    } else {
      this.socket.on(eventName, event => {
        store.dispatch({
          type: eventKey,
          scope: /^server/i.test(eventKey) ? 'SERVER' : 'CLIENT',
          payload: {
            event: eventName,
            data: event
          }
        });
        handler(event);
      });
    }
  };

  _addListeners = () => {
    this._attachListenerToSocket(
      'SERVER_USER_CONNECTED',
      this._SERVER_USER_CONNECTED
    );
    this._attachListenerToSocket('SERVER_RECONNECT', this._SERVER_RECONNECT);
    this._attachListenerToSocket(
      'SERVER_USER_REJECTED',
      this._SERVER_USER_REJECTED
    );
    this._attachListenerToSocket('SERVER_DISCONNECT', this._SERVER_DISCONNECT);
    this._attachListenerToSocket(
      'SERVER_ROOM_CONNECTED',
      this._SERVER_ROOM_CONNECTED
    );
    this._attachListenerToSocket(
      'SERVER_ROOM_REJECTED',
      this._SERVER_ROOM_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_ROOM_DISCONNECTED',
      this._SERVER_ROOM_DISCONNECTED
    );
    this._attachListenerToSocket(
      'SERVER_ROOM_DISCONNECT_REJECTED',
      this._SERVER_ROOM_DISCONNECT_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_ROOM_JOINED',
      this._SERVER_ROOM_JOINED
    );
    this._attachListenerToSocket('SERVER_ROOM_LEFT', this._SERVER_ROOM_LEFT);
    this._attachListenerToSocket(
      'SERVER_MESSAGE_ADDED',
      this._SERVER_MESSAGE_ADDED
    );
    this._attachListenerToSocket(
      'SERVER_MESSAGE_REJECTED',
      this._SERVER_MESSAGE_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_OFFLINE_MESSAGE_ADDED',
      this._SERVER_OFFLINE_MESSAGE_ADDED
    );
    this._attachListenerToSocket(
      'SERVER_OFFLINE_MESSAGE_REJECTED',
      this._SERVER_OFFLINE_MESSAGE_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_MESSAGE_NEW',
      this._SERVER_MESSAGE_NEW
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_CHANGE',
      this._SERVER_CHAT_CHANGE
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_ASSIGNED',
      this._SERVER_CHAT_ASSIGNED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_REASSIGNED',
      this._SERVER_CHAT_REASSIGNED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_UNASSIGNED',
      this._SERVER_CHAT_UNASSIGNED
    );
    this._attachListenerToSocket(
      'SERVER_TYPING_START',
      this._SERVER_TYPING_START
    );
    this._attachListenerToSocket(
      'SERVER_TYPING_STOP',
      this._SERVER_TYPING_STOP
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_CLOSING',
      this._SERVER_CHAT_CLOSING
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_PUSHED',
      this._SERVER_CHAT_PUSHED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_BOX_OPENED',
      this._SERVER_CHAT_BOX_OPENED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_BOX_OPEN_REJECTED',
      this._SERVER_CHAT_BOX_OPEN_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_SPEECH_BUBBLE_OPEN_REJECTED',
      this._SERVER_SPEECH_BUBBLE_OPEN_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_BOX_CLOSED',
      this._SERVER_CHAT_BOX_CLOSED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_BOX_CLOSE_REJECTED',
      this._SERVER_CHAT_BOX_CLOSE_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_HISTORY_SENT',
      this._SERVER_CHAT_HISTORY_SENT
    );
    this._attachListenerToSocket(
      'SERVER_CHAT_HISTORY_SEND_REJECTED',
      this._SERVER_CHAT_HISTORY_SEND_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_TRACK_VISITOR_CONFIRMED',
      this._SERVER_TRACK_VISITOR_CONFIRMED
    );
    this._attachListenerToSocket(
      'SERVER_TRACK_VISITOR_REJECTED',
      this._SERVER_TRACK_VISITOR_REJECTED
    );
    this._attachListenerToSocket(
      'SERVER_TEAM_ONLINE_STATUS',
      this._SERVER_TEAM_ONLINE_STATUS
    );
  };

  _SERVER_USER_CONNECTED = async data => {
    const { global, rooms = [], chosenWebSettingsIndex } = store.getState();
    const globalActiveRoomInfo = chosenWebSettingsIndex
      ? rooms[chosenWebSettingsIndex]
      : null;
    const activeRoomInfo = (() => {
      if (
        globalActiveRoomInfo &&
        (globalActiveRoomInfo.status === chatTypes.STATUS_NEW ||
          globalActiveRoomInfo.status === chatTypes.STATUS_ACTIVE)
      ) {
        return globalActiveRoomInfo;
      }
      return (
        rooms.find(r => r.room === getRoomNameFromLocation()) || // set the active room by the active href
        rooms.find(
          r =>
            r.status === chatTypes.STATUS_NEW ||
            r.status === chatTypes.STATUS_ACTIVE
        ) // otherwise
      );
    })();

    if (activeRoomInfo.room !== global.activeRoom) {
      store.dispatch(setActiveRoom(activeRoomInfo.room));
    }

    if (this.props.userId !== data.userId) {
      this.props.userId = data.userId;
      store.dispatch(setUserId(data.userId));
    }

    /**
     * If it's super account and user don`t have current rooms
     */
    if (!global.activeRoom || rooms.length === 0 || !activeRoomInfo.connected) {
      if (activeRoomInfo && activeRoomInfo.visible) {
        this._connectToRoom(activeRoomInfo);
      } else {
        this._connectToRooms();
      }
    }
  };

  _SERVER_RECONNECT = () => {
    this._identity(this.props.userId);
  };

  _SERVER_USER_REJECTED = () => {
    this.props.configurator.destroy();
    this._destroy();
  };

  _SERVER_DISCONNECT = () => {
    const { rooms } = store.getState();
    rooms.forEach(r => store.dispatch(disconnectRoom(r.room)));
  };

  _SERVER_ROOM_CONNECTED = async data => {
    const {
      room,
      chatId,
      operatorName,
      operatorAvatar,
      operatorOnline,
      operatorAssigned,
      operatorId
    } = data;
    const { global, rooms = [], chatBox } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (!room) return;

    store.dispatch(connectRoom(room, chatId));

    if (roomInfo.status !== chatTypes.STATUS_NEW) {
      await store.dispatch(loadRoomHistory(room, chatId));
    }

    store.dispatch(
      operatorAssignedChat(
        (() => {
          const data = { room };

          if (typeof operatorAssigned === 'boolean') {
            data.operatorIsAssigned = operatorAssigned;
            data.operatorIsFakeAssigned = operatorAssigned;
          }

          if (typeof data.needAnswer === 'boolean') {
            data.waitingOperator = data.needAnswer;
          }
          return data;
        })()
      )
    );

    store.dispatch(
      setOperatorInfo(room, {
        room,
        chatId,
        operatorId,
        name: operatorName || '',
        avatar: operatorAvatar
      })
    );

    if (typeof operatorOnline === 'boolean') {
      if (operatorOnline) {
        store.dispatch(setOperatorOnline(room));
      } else {
        store.dispatch(setOperatorOffline(room));
      }
    }

    if (chatBox.open) {
      this._chatBoxOpened(room);
    }
    // Global connection initialization (once)
    if (!global.connection) {
      store.dispatch(connect());
      this._connectToRooms({ exclude: [room] });
    }
  };

  _SERVER_ROOM_REJECTED = data => {
    const { origData = {}, error = {} } = data;
    const { global, rooms } = store.getState();
    const { activeRoom } = global;
    const letContinue =
      error.code === chatTypes.ERROR_CHAT_INACTIVE &&
      origData.room !== activeRoom;
    const room = origData.room;
    const roomInfo = rooms.find(r => r.room === room);

    if (!letContinue) return;
    if (!roomInfo) return;

    store.dispatch(reinitRoomData(room));
  };

  _SERVER_ROOM_DISCONNECTED = data => {
    const room = data.room;
    store.dispatch(reinitRoomData(room));
  };

  _SERVER_ROOM_DISCONNECT_REJECTED = data => {};

  _SERVER_ROOM_JOINED = data => {
    store.dispatch(setOperatorOnline(data.room));
  };

  _SERVER_ROOM_LEFT = data => {
    store.dispatch(setOperatorOffline(data.room));
  };

  _SERVER_MESSAGE_ADDED = data => {
    const { room, message } = data;
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);
    if (!roomInfo) return;

    store.dispatch(setStatus(room, chatTypes.STATUS_ACTIVE));
    store.dispatch(setNeedAnswer({ room, needAnswer: true }));
    store.dispatch(
      setMsgStatus({
        room,
        text: message,
        status: messageStatuses.MSG_STATUS_SENT
      })
    );

    // if ('Notification' in window && Notification.permission === 'default') {
    //   // Taken from MDN docs example.
    //   // Function to check whether browser supports the promise version of requestPermission()
    //   // Safari only supports the old callback-based version
    //   const checkNotificationPromise = () => {
    //     try {
    //       Notification.requestPermission().then();
    //     } catch (e) {
    //       return false;
    //     }
    //
    //     return true;
    //   };
    //
    //   if (checkNotificationPromise()) {
    //     Notification.requestPermission().then();
    //   } else {
    //     Notification.requestPermission(() => {});
    //   }
    // }
  };

  _SERVER_MESSAGE_REJECTED = (data = {}) => {
    NotificationManager.error('Failed to send message', 'Error');
    const { origData: { room, message } = {} } = data;
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);
    if (!roomInfo) return;
    store.dispatch(
      setMsgStatus({
        room,
        text: message,
        status: messageStatuses.MSG_STATUS_ERROR
      })
    );
  };

  _SERVER_OFFLINE_MESSAGE_ADDED = data => {
    NotificationManager.success('Message successfully sent', 'Success');
  };

  _SERVER_OFFLINE_MESSAGE_REJECTED = data => {};

  _SERVER_MESSAGE_NEW = data => {
    const { room, isSystem, userId } = data;
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (roomInfo.timer && roomInfo.closingTime === 0) {
      store.dispatch(reinitRoomData(data.room));
    } else {
      store.dispatch(clearClosedChatTimer(room));
    }

    if (typeof isSystem === 'boolean' && !isSystem) {
      store.dispatch(setStatus(room, chatTypes.STATUS_ACTIVE));
    }

    store.dispatch(
      sendMessage(data.room, {
        author: 'operator',
        date: getCurrentTime(),
        text: data.message,
        operatorId: userId,
        isSystem
      })
    );

    // const Styles = new StylesConstructor({ settings: global.settings });
    // const icon = Styles.getFBImg();
    // if ('Notification' in window && Notification.permission === 'granted') {
    //   // eslint-disable-next-line no-new
    //   new Notification(room, { body: 'New chat message', icon });
    // }
  };

  _SERVER_CHAT_CHANGE = data => {
    store.dispatch(clearClosedChatTimer(data.room));
    store.dispatch(loadRoomHistory(data.room, data.newChatId));
    store.dispatch(setChatId(data.room, data.newChatId));
  };

  _SERVER_CHAT_ASSIGNED = data => {
    store.dispatch(
      setOperatorInfo(data.room, {
        ...data,
        name: data.userName,
        avatar: data.avatar,
        operatorId: data.userId,
        emitEvent: this.emitEvent
      })
    );

    store.dispatch(
      operatorAssignedChat({
        room: data.room,
        operatorIsAssigned: true,
        waitingOperator: false,
        lastMessageSocketId: data.lastMessageSocketId
      })
    );

    store.dispatch(clearLamTimer(data.room));
  };

  _SERVER_CHAT_REASSIGNED = data => {};

  _SERVER_CHAT_UNASSIGNED = (data = {}) => {
    const { rooms = [] } = store.getState();
    const { room, holdChatId } = data;
    const storedChatId = (() => {
      if (holdChatId) {
        const roomInfo = rooms.find(r => r.room === room) || {};
        const { chatId } = roomInfo;
        return chatId;
      }
      return undefined;
    })();

    store.dispatch(operatorUnassignedChat(room));
    store.dispatch(
      storeChatId({
        room,
        chatId: storedChatId
      })
    );
  };

  _SERVER_TYPING_START = data => {
    store.dispatch(setOperatorTyping(data.room));
  };

  _SERVER_TYPING_STOP = data => {
    store.dispatch(unsetOperatorTyping(data.room));
  };

  _SERVER_CHAT_CLOSING = data => {
    const { global } = store.getState();
    const { storedChatIds = {} } = global;
    const { settings = {} } = global;
    const { room, closingTimeout, operatorOnline, departmentName } = data;
    const closeAt = closingTimeout
      ? new Date().getTime() + closingTimeout * 1000
      : 0;
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (!roomInfo) return;

    if (typeof operatorOnline === 'boolean' && !operatorOnline) {
      this._closeChat(room);
    } else {
      if (settings.cb_visitor_offer_email === 'on') {
        store.dispatch(showSendTranscriptForm({ room, show: true }));
      }
      store.dispatch(
        operatorClosedChat(data.room, {
          ...data,
          closeAt,
          closingTimeout,
          departmentName
        })
      );
    }

    if (getClearRoomName(room) in storedChatIds) {
      store.dispatch(storeChatId({ room, chatId: undefined }));
    }
  };

  _SERVER_CHAT_PUSHED = (data = {}) => {
    if (data.url) {
      window.location.href = data.url;
    }
  };

  _SERVER_CHAT_BOX_OPENED = data => {
    const { chatBox } = store.getState();

    if (!chatBox.open) {
      store.dispatch(openCb());
    }
  };

  _SERVER_CHAT_BOX_OPEN_REJECTED = data => {};

  _SERVER_SPEECH_BUBBLE_OPEN_REJECTED = data => {};

  _SERVER_CHAT_BOX_CLOSED = data => {};

  _SERVER_CHAT_BOX_CLOSE_REJECTED = data => {};

  _SERVER_CHAT_HISTORY_SENT = data => {
    this._closeChat(data.room);
  };

  _SERVER_CHAT_HISTORY_SEND_REJECTED = data => {};

  _SERVER_TRACK_VISITOR_CONFIRMED = ({ room, pageUrl }) => {
    logger.verbose(
      `SERVER_TRACK_VISITOR_CONFIRMED: ${room} accessing "${pageUrl}"`
    );
  };

  _SERVER_TRACK_VISITOR_REJECTED = data => {
    logger.verbose(`SERVER_TRACK_VISITOR_REJECTED: ${JSON.stringify(data)}`);
  };

  _SERVER_TEAM_ONLINE_STATUS = data => {
    logger.verbose(`SERVER_TEAM_ONLINE_STATUS: ${JSON.stringify(data)}`);
    store.dispatch(setDomainQualificationTeamStatus(data));
  };

  _identity = userId => {
    const nowTS = Date.now();
    const { lastReconnectTry, reconnectDelay } = this.props;

    if (lastReconnectTry && nowTS - lastReconnectTry < reconnectDelay) {
      return;
    }

    this.props.lastReconnectTry = nowTS;
    this.socket.emit(msgTypes.CLIENT_USER_ADD, { userId });
  };

  _connectToRooms = (options = {}) => {
    const { exclude = [] } = options;
    const { rooms } = store.getState();
    rooms.forEach(room => {
      if (exclude.includes(room)) {
        return;
      }
      if (!room.visible) {
        return;
      }
      if (room.connected) {
        return;
      }
      this._connectToRoom(room);
    });
  };

  _connectToRoom = ({
    room,
    domainId,
    accountId,
    superId,
    groupId,
    chatId = null,
    dealerName
  }) => {
    const { global, rooms = [] } = store.getState();
    const {
      meta = {},
      activeRoom,
      storedChatIds = {},
      chosenWebSettingsIndex,
      webSettings = []
    } = global;
    if (!room) {
      return;
    }
    if (domainId !== meta.domain_id) {
      return;
    }

    const roomInfo = rooms.find(r => r.room === room);
    const clearRoomName = getClearRoomName(room);
    const sendData = {
      room,
      domainId,
      accountId,
      superId,
      groupId,
      chatId:
        chatId ||
        (clearRoomName in storedChatIds ? storedChatIds[clearRoomName] : null),
      dealerName,
      validate: room !== activeRoom && chatId,
      needAnswer: getProp(roomInfo, 'needAnswer', false),
      referrer: this.props.referrer,
      pageUrl: this.props.pageUrl,
      isMobile: isPhone
    };

    if (webSettings.length > 0) {
      const chosenWebSettings = webSettings[chosenWebSettingsIndex || 0];
      if (
        chosenWebSettings &&
        chosenWebSettings.vcSettings &&
        chosenWebSettings.vcSettings.data &&
        chosenWebSettings.vcSettings.data.vehicleDetails
      ) {
        sendData.vehicleDetails = JSON.stringify(
          chosenWebSettings.vcSettings.data.vehicleDetails
        );
      }
    }
    this.socket.emit(msgTypes.CLIENT_ROOM_CONNECT, sendData);
  };

  _runLockTimer = (room, timeout = 0) => {
    return setTimeout(() => this._closeChat(room), timeout);
  };

  _onChatSuspended = (room, closeDate, departmentName) => {
    const { global } = store.getState();
    const { settings = {} } = global;

    if (settings.cb_visitor_offer_email === 'on') {
      store.dispatch(showSendTranscriptForm({ room, show: true }));
    }
  };

  _disconnectRoom = room => {
    const { rooms } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);
    if (!roomInfo || !roomInfo.connected) return;
    this.socket.emit(msgTypes.CLIENT_ROOM_DISCONNECT, {
      room
    });
  };

  _getRoomsByVisibility = (visible = true) => {
    const state = store.getState();
    return state.rooms.reduce((accumulator, currentRoom) => {
      if (currentRoom.visible === visible) accumulator.push(currentRoom.room);
      return accumulator;
    }, []);
  };

  /**
   * Open chat box event
   * @private
   */
  _chatBoxOpened = room => {
    if (room) {
      this.socket.emit(msgTypes.CLIENT_CHAT_BOX_OPEN, { room });
    }
  };

  /**
   * Open speech bubble event
   * @private
   */
  _speechBubbleOpened = room => {
    if (room) {
      this.socket.emit(msgTypes.CLIENT_SPEECH_BUBBLE_OPEN, { room });
    }
  };

  /**
   * Closed chat box event
   * @private
   */
  _chatBoxClosed = room => {
    if (room) {
      this.socket.emit(msgTypes.CLIENT_CHAT_BOX_CLOSE, { room });
    }
  };

  /**
   * Closed chat
   * @param room
   * @private
   */
  _closeChat = room => {
    const { rooms = [] } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (!roomInfo) {
      return;
    }

    if (roomInfo.timer) {
      store.dispatch(clearClosedChatTimer(room));
    }
    if (roomInfo.connected) {
      this._disconnectRoom(room);
    }

    if (rooms.length === 1) {
      store.dispatch(reinitRoomData(room));
    } else {
      store.dispatch(setStatus(room, chatTypes.STATUS_CLOSED));
    }
  };

  /**
   * Send message
   * @param message
   * @param isSystem
   * @private
   */
  _sendMessage = (message, isSystem = false) => {
    const { global, rooms = [] } = store.getState();
    const {
      webSettings = [],
      chosenWebSettingsIndex,
      activeRoom,
      connection
    } = global;

    const activeRoomInfo = rooms.find(r => r.room === activeRoom);

    if (!connection) {
      return;
    }

    const eventMsg = { room: activeRoom, message: message };
    if (
      webSettings.length > 0 &&
      (activeRoomInfo.status === chatTypes.STATUS_NEW ||
        activeRoomInfo.status === chatTypes.STATUS_SUSPENDED)
    ) {
      const chosenWebSettings = webSettings[chosenWebSettingsIndex || 0];

      if (chosenWebSettings && chosenWebSettings.vcSettings) {
        eventMsg.pageSettings = JSON.stringify(chosenWebSettings.vcSettings);
      }
    }

    // If this chat is not assigned then send the qualification process along with the chat.
    if (
      !activeRoomInfo.operatorIsAssigned &&
      activeRoomInfo.qualificationProcess.active &&
      activeRoomInfo.qualificationProcess.completed
    ) {
      // If we already have client chat messages, then assume we have already sent the initial qualification process.
      const hasClientMessages = this._chatAdHoc.clientMessageIsExists(
        activeRoomInfo.chatListMessages
      );
      if (!hasClientMessages) {
        eventMsg.qualificationStepAnswers = activeRoomInfo.qualificationProcess.history
          .filter(history => history.question === undefined)
          .map(history => {
            return { id: history.id, name: history.name };
          });

        // Set mb_initial_message_sent boolean
        if (activeRoomInfo.mbInitialMessage) {
          eventMsg.mbInitialMessage = activeRoomInfo.mbInitialMessage;
        }
      }

      // Determine if we need to send this to a specific team.
      const lastAnswer =
        activeRoomInfo.qualificationProcess.history[
          activeRoomInfo.qualificationProcess.history.length - 1
        ];
      if (lastAnswer.account_team_id) {
        eventMsg.teamId = lastAnswer.account_team_id;
      }
    }

    if (isSystem) {
      eventMsg.isSystem = true;
    }

    this.socket.emit(msgTypes.CLIENT_MESSAGE_ADD, eventMsg);
  };

  /**
   * Send offline message
   * @param name
   * @param phone
   * @param email
   * @param message
   * @private
   */
  _sendOfflineMessage = ({ name, phone, email, message }) => {
    const { global } = store.getState();
    const { activeRoom, connection } = global;

    if (!connection) {
      return;
    }

    this.socket.emit(msgTypes.CLIENT_OFFLINE_MESSAGE_ADD, {
      room: activeRoom,
      name,
      phone,
      email,
      message
    });
  };

  /**
   *
   * @param data
   * @private
   */
  _sendChatHistoryMessage = data => {
    const { global } = store.getState();
    const { connection } = global;

    if (!connection) {
      return;
    }

    this.socket.emit(msgTypes.CLIENT_CHAT_HISTORY_SEND, data);
  };

  /**
   *
   * @param data
   * @private
   */
  _sendDetailsFormMessage = data => {
    const { global } = store.getState();
    const { connection } = global;

    if (!connection) {
      return;
    }

    this.socket.emit(msgTypes.CLIENT_DETAILS_FORM_SEND, data);
  };

  /**
   *
   * @param data
   * @param isStart
   * @private
   */
  _setTypingState = (data, isStart) => {
    const type = isStart
      ? msgTypes.CLIENT_TYPING_START
      : msgTypes.CLIENT_TYPING_STOP;
    this.socket.emit(type, data);
  };

  /**
   * Send a client track event, useful for SPA websites.
   *
   * @param {string} pageUrl
   * @private
   */
  _sendTrackVisitor = ({ pageUrl }) => {
    logger.debug(`ChatConnection._sendTrackVisitor: ${pageUrl}`);
    const { global } = store.getState();
    const { activeRoom, connection } = global;

    if (!connection) {
      return;
    }

    const eventMsg = { room: activeRoom, pageUrl };
    this.socket.emit(msgTypes.CLIENT_TRACK_VISITOR, eventMsg);
  };

  _getTeamOnlineStatus = messageData => {
    this.socket.emit(msgTypes.SERVER_TEAM_ONLINE_STATUS, messageData);
  };

  _chatNoAnswerTimeout = messageData => {
    this.socket.emit(msgTypes.SERVER_NO_ANSWER_TIMEOUT, messageData);
  };

  _resetRoom = room => {
    const { rooms = [] } = store.getState();
    const roomInfo = rooms.find(r => r.room === room);

    if (!roomInfo) {
      return;
    }

    store.dispatch(reinitRoomData(room, { chatId: null }));
    if (roomInfo.qualificationProcess && roomInfo.qualificationProcess.active) {
      store.dispatch(domainQualificationReset(room));
    }

    roomInfo.chatId = null;
    this._disconnectRoom(room);
    this._connectToRoom(roomInfo);
  };

  _destroy() {
    this.props = { ...this.initProps };
    this.socket.close();
  }
}
